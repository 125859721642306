import React from 'react';
import Title from './Title';
import CardFlipped from './CardFlipped';
import Card from './Card';
import styled from 'styled-components';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'gatsby';

const Sold = ({ sold = [] }) => {
  const featured = sold.filter((property) => property.featured);

  return (
    <Container>
      <Title title="recently sold" link="/properties/sold" />
      <div className="section-center">
        {featured.map((item, index) => {
          return index % 2 === 0 ? (
            <Card item={item} key={index} />
          ) : (
            <CardFlipped item={item} key={index} />
          );
        })}
      </div>
      <div className="btn-container">
        <Link to="/properties/sold" className="more-btn">
          see more
          <FiChevronRight />
        </Link>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 2.5rem;

  .section-center {
    flex-direction: column;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  svg {
    font-size: 2rem;
    padding-top: 0.25rem;
    transition: 0.5s;
  }
  .more-btn {
    font-size: 2rem;
    color: #2c3e50;
    display: flex;
    align-items: center;
    &:hover > svg {
      transition: 0.5s;
      transform: translateX(1rem);
    }
  }
`;

export default Sold;
