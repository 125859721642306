import React from 'react';
import styled from 'styled-components';
import Title from './Title';
// import Form from './Form';
import ContactDetails from './ContactDetails';

const Contact = () => {
  return (
    <Container className="section" id="contact">
      <div className="section-center">
        <Title title="Get In Touch" link="/contact" />
        <div className="contact-content">
          {/* <Form /> */}
          <ContactDetails />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;

  .section-center {
    display: flex;
    flex-direction: column;
  }
  .contact-content {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 800px) {
    .contact-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export default Contact;
