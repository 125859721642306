import React from 'react';
import Hero from '../components/Hero';
import About from '../components/About';
import Contact from '../components/Contact';
import Products from '../components/Products';
// import Services from '../components/Services';
import styled from 'styled-components';
import '../assets/css/index.css';
import Layout from '../components/Layout';

const Home = () => {
  return (
    <Main>
      <Layout>
        <Hero />
        <Products />
        {/* <Services /> */}
        <About />
        <Contact />
      </Layout>
    </Main>
  );
};

const Main = styled.main`
  width: 100%;
  position: relative;
`;

export default Home;
