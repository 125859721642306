import React from 'react';
// import ForSale from './ForSale';
import Sold from './Sold';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  {
    allContentfulProperties {
      nodes {
        id
        featured
        title
        bedrooms
        bathrooms
        description {
          description
        }
        listPrice
        images {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          title
        }
        tags {
          content
        }
      }
    }
  }
`;

const Products = () => {
  const {
    allContentfulProperties: { nodes: properties },
  } = useStaticQuery(query);

  // const forsale_properties = properties.filter((property) => {
  //   const tag = property.tags[0].content;
  //   return tag === 'forsale' ? property : null;
  // });
  const sold_properties = properties.filter((property) => {
    const tag = property.tags[0].content;
    return tag === 'sold' ? property : null;
  });

  return (
    <Container className="section">
      {/* <ForSale forsale={forsale_ properties} /> */}
      <Sold sold={sold_properties} />
    </Container>
  );
};

const Container = styled.section`
  .section {
    padding-bottom: 0;
  }
  .section-center {
    flex-direction: column;
  }
`;

export default Products;
